$color-primary-bg:#337AB7;
$color-primary-border:#2E6DA4;

$grey-light: #fafafa;
$grey:#E0E0E0;
$grey-dark:#ABABAB;
$grey-cancel-button: #efefef;
$grey-close-button: #efefef;

$color-success-bg:#5CB85C;
$color-success-border:#4CAE4C;
$color-reviewed-icon: #4CAF50;

$color-approve-button-background: #449d44;
$color-border-approve-button-focus: #255625;
$color-border-approve-button-hover: #398439;

$color-warning-bg:yellow;

$color-validacion-danger:#dc3545;
$color-user-left-dcf: #bf0000;

$white: #ffffff;
$black: #333;
$color-label-disabled: #333;
$filter-icon: #444;

$yellow-autosave-conflicts: #fff3cd;
$green-autosave-edited-externally: #d4edda;
