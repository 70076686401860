@import '/src/styles/abstracts/variables';
#DCFEngagementTeam{
    width: 100% !important;
}
.page-margin {
    margin: 15px;
}
#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input
{
  //  width: 130px!important;
}
#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-search-input
{
    width: 214px !important;
}
#peoplePickerDivMemberFirmTeam_chosen{
    top: -7px!important;
}

.submit-btn {
    background-color: #337AB7;
    border-color: #2E6DA4;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    border: 2px solid #2E6DA4;
}

.save-rework-cancel-btn {
    background-color: #FFFFFF;
    border-color: #337AB7;
    color: #337AB7;
    text-align: center;
    font-size: 12px;
    border: 2px solid #337AB7;
}

.btn-approve {
    background-color: #5CB85C;
    border-color: #4CAE4C;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    border: 2px solid #4CAE4C;
}

.btn-download-template{        
    border-color: #BDBDBD;
    background-color: #FFFFFF;
    color: #212121;    
    height: 1.5em !important;  
    min-width: 6em;  
}

.btn-download-template:hover{ 
    border-color: #92C0E0;
    background-color: #E6F2FA;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-radio {
    margin-left: 5px !important;
}

textarea {
    margin-bottom: 0px !important;
}

    textarea:disabled {
        background: #f1f1f1;
        border-color: #cccccc;
        border: 1px solid;
    }

.bg-title {
    background-color: #337AB7;
    color: white;
}

.container-border-color {
    border: 1px solid #337AB7;
    border-radius: 2px;
}

.icon-file-color {
    color: #337ab7;
}

.display-inline-block {
    display: inline-block;
}

.display-inline-flex {
    display: inline-flex;
}

.display-inline, #up-document-section {
    display: inline;
}

.row {
    padding: 5px 10px;
}

.text-area-style {
    width: 98.9%;
    resize: none;
    scroll-behavior: auto;
    margin-top: 5px;
    border-radius: 2px;
}

.margin-top-4 {
    margin-top: 4px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.padding-top-10 {
    padding-top: 10px;
}

.width-20 {
    width: 20%;
}

.width-25 {
    width: 25%;
}

.width-45 {
    width: 45%;
}  

.width-55 {
    width: 55%;
}   

.width-72 {
    width: 72%;
} 

.width-75 {
    width: 75%;
} 

.width-80 {
    width: 80%;
}

.width-85 {
    width: 85%;
}

.width-86 {
    width: 86%;
}

.width-93 {
    width: 93%;
}

.width-95 {
    width: 95%;
}

.width-97 {
    width:100%;
}

.full-width {
    width: 100%;
}

.sp-peoplepicker-topLevel {
    width: 100% !important;
    border-radius: 2px;
}

.sp-peoplepicker-editorInput {
    margin-top: 0px !important;
}

select:disabled {
    color: darkgray;
}

.bg-modal-button-cancel {
    background-color: #efefef;
}

#discontinue-reason-label {
    float: left;
    padding-left: 42px;
}

#DiscontinueReason, #PutOnHoldReason {
    width: 85%;
}

#discontinue-with-reason-modal .modal-footer, #put-on-hold-with-reason-modal .modal-footer {
    margin-right: 42px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.padding-left-2 {
    padding-left: 2px;
}        

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
} 

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-15 {
    padding: 15px;
}

.height-24 {
    height: 24px;
}

.height-30 {
    height: 57px;
}

.height-70 {
    height: 120px;
}

.height-230 {
    height:252px;
}

.open-selector-file, #upload-file, #add-additional-document-types {
    height: 29px !important;
    line-height: 0px;
}

#add-additional-document-types:hover, #add-additional-document-types:focus, #add-additional-document-types:active {
    border-color: #92C0E0;
    background-color: #E6F2FA;
    color: black;
}


/*
    Start of "Requires final version" feature styles
*/
.requires-final-version .requires-final-version-label {
    font-size: 12px;
    font-style: italic;
    padding-top: 5px;
    font-family: "Segoe UI";
}

.requires-final-version a.mark-as-final {
    height: auto;
    padding: 0 3px;
    font-size: 8px;
    color: #cecece;
    border: 2px solid #d2d2d2;
    border-radius: 4px;
    cursor: pointer;
    transform: translate(0, 3px);
}

.requires-final-version a.mark-as-final.marked-as-final {
    background: #337ab7;
    border-color: #337ab7;
    color: white;
}

.requires-final-version a.mark-as-final:hover {
    border-color: #92C0E0;
    background-color: #E6F2FA;
    color: black;
}

span.tooltiptext-dcf.marked-as-final-tooltip {
    top: 22px;
    margin-left: unset;
    left: 0;
}
/*
    End of "Requires final version" feature styles
*/

.name-file {
    color: #333 !important;
}

.padding-left-0 {
    padding-left: 0px;
}

.modal-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.ul-no-style {
    list-style-type: none;
}

.delete-chosen-file, .delete-uploaded-file, .delete-uploaded-file-style,.delete-uploaded-fin-capitalizations-file {
    top: 2px;
    left: 5px;
}

.position-absolute-margin-left {
   position: absolute;
   margin-left: 98%;
}

.glyphicon-remove {
    cursor: pointer;
}

.margin-top-down-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}        

.display-flex {
    display: flex;
}

.display-table {
    display: table;
}        

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.modal-footer-border {
    border-top: 0px !important;
}

hr {
    margin-top: 0px;
    margin-bottom: 0px;
}

.border-hr {
    border-bottom: 0.04em solid;
    border-color: #b1b1b1;
}

table td {
    border: 1px solid #b1b1b1;
}

input[type=text]:not(.signature-color) {
    margin-top: 5px;
}

.border-radius-2 {
    border-radius: 2px;
}

.bg-dcf-engagement-team {
    background: #e6e6e6;
}


.padding-dcf-engagement-team {
    padding: 8px 5px 10px 5px;
}

input[type=file] {
    padding: 0px !important;
}

input[type=text][disabled]:not(.signature-color) {
    //border-color: darkgrey;
}

#signature-color {
    color: #333 !important;
    border: none;
}

.dropdown-menu {
    max-height: 300px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

#submit-modal p, h1 {
    color: #0072C6;
}
.input-data-file {
    visibility: hidden;
    width: 0px;
}
button:hover{
    background-color: #d6e6f1;
    border-color: #9bc2de ;
}
input:focus
{
    border:1px solid rgb(59, 172, 210);
    border-color:rgb(59, 172, 210);
    border-style: solid 1px !important;
}
.input-sizing{
    height: 25px;
    border-radius: 2px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
    color: #444;
}
.border-bold{
    border: 1px solid #ABABAB;
}
textarea:focus{
    border:1px solid rgb(59, 172, 210);
    border-color:rgb(59, 172, 210);
    border-style: solid 1px !important;
}
.bg-missingRequiredField {
    background-color: yellow;
}

.page-margin table tr td {
    padding: 5px;
}       

.float-right {
    float: right;
}

.no-margin-no-padding {
    margin: 0px;
    padding: 0px;
}

span.required-file:after, span.red-asterik:after {
    content: " *";
    color: red;
}

span.numeric-validation-msg {        
    color: red; 
    font-size: 13px;              
}

.visibility-hidden {
    visibility: hidden;
}
.Comment-Space{
    margin-top: 11px;
}

.sp-peoplepicker-topLevelDisabled {
    background: #f1f1f1;
    border-color: #cccccc;
    border: 1px solid; 
}

.sp-peoplepicker-topLevelReadonly {
    background: white;
}

.top-30 {
    top: 30%;
    position: relative;
}

.top-18 {
    top: 18%;
    position: relative;
    margin: 1px;
}

.top-5 {
    position: relative;
    top: 5px;
}

.width-75vw {
    width:75vw;
}

.no-padding-left-right div {
    padding-right: 0px;
    padding-left: 0px;
}

.no-margin-left-right {
    margin-left: 0px;
    margin-right: 0px;
}

.no-margin {
    margin: 0px !important;
}

.templates > div {      
    border-radius: 6px;
    text-align:center;
    background-color: #337AB7;
    color: white;
}

.review-status > div {
    height: 31.5px;
}

.document-name > div {
    height: 65px;
}

.attachment-rows button {
    width: 85%;
}

.attachment-rows .row {
    padding: 10px 10px;
}

.attachments-rows hr {
    border-color: #bdbdbd;
    margin: 2px 0px 2px 8px;
}

.glyphicon-file {
    color: #337ab7;
    font-size: x-large;
}

.fa {
    color: #424242;
    font-size: 24px !important;
    font-weight: 900 !important;
    text-align: center;
}

.close-modal {
    font-weight: unset;
    opacity: unset;
}

.min-width-0 {
    min-width: 0em;
}

.close-modal-span {
    font-size: larger;
    float: right;
    color: #656565;
}

.modal-title-header-color {
    color: #292929;
}

* {
    outline: none;
}

.modal-content-attachments .modal-content {
    background-color: #F5F5F5 !important;
}

.modal-span {
    width: 95%;
    display: inline-flex;
    margin: 1em;
}

.close-modal-btn {
    background-color: #E0E0E0;
    border-color: #9E9E9E;
    color: #212121; 
    height: 1.5em !important;
}

.border-bottom-2 {
    border-bottom: 2px solid white;
}

.border-right-2 {
    border-right: 2px solid white;
}

.border-right-1 {
    border-right: 1px solid white;
}

.border-left-1 {
    border-left: 1px solid white;
}

.border-left-2 {
    border-left: 2px solid white;
}

.col-6-width-first {
    width: calc(50% - 2px) !important;
}

.col-6-width {
    width: calc(50% - 1px) !important;
}

.col-12-width {
    width: calc(100% - 2px) !important;
}

.col-4-width {
    width: calc(33.33333333% - 3px) !important;
}

.padding-bottom-2 {
    padding-bottom: 2px;
}

_:-ms-lang(x), _:-webkit-full-screen, .templates-header { 
    font-size: 16px;
}

_:-ms-lang(x), _:-webkit-full-screen, .review-status-top { 
    top: 3px;
}

@media only screen and (min-width: 1366px) {
    .document-template {
        top: 18%;
    }
}

.risk-rating-section-bg {
    background-color: white;
}

.administrator-section-border {
    border-radius: 3px;
    border: 1px solid #aaaaaa;
}

.risk-section-box {
   border: 1px solid #bebebe;
   border-radius:3px;
}

.risk-section-box #risk-section-title {
   padding: 0px 5px;
   position: relative;
   top : -0.7em;
   margin-left: 10px;
   display: inline;
   background-color: white;
}

.margin-left-48 {
   margin-left: 48px;
}

.incomes-margin-left {
    margin-left: 40px;
}

.margin-right-10 {
   margin-right:10px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.risk-rating-margin {
    margin-top: 0px;
    margin-bottom: 20px;
}

.risk-rating-margin > span {
    margin-right: 6em;
    font-size: 15px;
    margin-left: 5px;
}

.risk-rating-margin > .risk-selector-option, .radios-capital-raise > .input-data-option {
    width: 1.5em;
    height: 1em;
}

.risk-rating-margin > input[type="radio"] {
    position: relative;
    margin-left: 0px;
}

.padding-left-11 {
    padding-left: 11px;
}

.padding-left-5 {
    padding-left: 5px;
}

.reviewed-icon-color {
    color:#4CAF50;
}

.pending-icon-color {
    color:#FF9800;
}

.return-for-rework-icon-color {
    color:#F44336;
}

.review-na-icon-color {
    color:#666666;
}

.display-block {
    display:block;
}

.review-assistant-buttons {
    font-size: 38px !important;
    text-align: center;
    cursor: pointer;
    margin-bottom: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.no-cursor-pointer {
    cursor: default;
}

.review-assistant-open-box {
    margin-top: 1px;
    vertical-align: text-top;
}

.margin-right-20 {
    margin-right: 20px;
}

.label-review-assistant-button {
    font-size: 12px;
    display: flex;
}

.review-na {
    font-size: 36px !important;
    margin-bottom: 9px;
    top: 3px;
}

.review-assistant-icon-status {
    font-size: 22px;
    vertical-align: text-top;
    margin-top: 1px;
}

.div-icon-footer {
    margin-right: 25px;
    text-align: center;
}

.bottom-1 {
    bottom: 1px;
    position: relative;
}

.document-name-padding {
    padding-right: 15px;
    padding-left: 9px;
    margin: 0px;
}

.document-type-button-color-green {
    background-color: #5CB85C;
    border-color: #5CB85C;
}

.document-type-button-color-green:active {
    background-color: #5CB85C;
    border-color: #5CB85C;
}

.document-type-button-color-green:hover {
    background-color: #449d44;
    border-color: #255625;
}

.document-type-button-color-green:focus {
    background-color: #449d44;
    border-color: #255625;
}

.word-break-all {
    word-break: break-all;
}

.top-4 {
    top: 4px;
    position: relative;
}

.bottom-3 {
    bottom: 3px;
}

.font-size-24 {
    font-size: 24px;
}

.icon-spinner-review-assistant {
    font-size: 36px !important;
}

.modal-footer-document-template {
    height: 100px;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.half-a-border {
    border-top: 1px solid #bebebe;
    border-left: 1px solid #bebebe;
    border-right: 1px solid #bebebe;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.half-a-border:after {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 1px;
    height: 50%;
    background-color: white;
    position: absolute;
    bottom: 0;
    right: -1px;
}

.half-a-border:before {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 1px;
    height: 50%;
    background-color: white;
    position: absolute;
    bottom:0;
    left:-1px;
}

.padding-row-span-comments {
    padding-left: 14px;
    padding-right: 14px;
}

.float-left {
    float: left;
}

.internal-comments-bar-color {
    border-left: 10px solid #e0e0e0;
    height: 70px;
}

.external-comments-bar-color {
    border-left: 10px solid #337ab7;
    height: 70px;
}

.margin-rigth-12 {
    margin-right: 12px;
}

.margin-left-12 {
    margin-left: 12px;
}

.margin-left-14 {
    margin-left: 14px;
}

.div-comments {
    height: 70px;
    bottom: 32px;
    position: relative;
}

.margin-left-8 {
    margin-left: 8px;
}

.height-70 {
    height: 120px;
}

.bottom-24 {
    bottom: 10px;
}

.margin-left-4 {
    margin-left: 4px;
}

.textarea-comments {
    border: none!important;
overflow: auto!important;
outline: none!important;
    width: 100%;
    resize: none;
    scroll-behavior: unset;
    top: 2px;
    position: relative;
    text-align: justify;
    color: #777;
}

.font-size-12 {
    font-size: 12px;
}

.margin-top-8 {
    margin-top: 8px;
}

.div-without-padding div {
    padding: 0px;
}

.margin-top-25 {
    margin-top: 25px;
    height: 36px;
}

.attachment-section-document-type a, .redline-section a {
    color:#f44336;
}

.modal-body-review-assistant {
    overflow-y: auto;
    text-align: center;
    height: 230px;
}

.modal-body-review-assistant .delete-uploaded-file {
    display: none;
}

.redline-attachment-section-modal {
    margin-top: 25px;
}

.redline-attachment-section-modal li {
    text-align: left;
    top: 3px;
}

.redline-attachment-section-modal span {
    padding-left: 8px;
}

.modal-attachment-section li {
    text-align: left;
    top: 0px;
}

.modal-attachment-section a:visited {
    color: #0072c6;
}

#review-assistant-modal .redline-attachment-section-modal .attachment-section-document-type .file-list .mark-as-final {
    display: none;
}

#review-assistant-modal .redline-attachment-section-modal .attachment-section-document-type .file-list {
    margin-left: 0;
}


.display-none {
    display: none;
}

.z-index-max {
    z-index: 100000000000000;
}

.z-index-1 {
    z-index: 1;
}

.z-index-0 {
    z-index: 0;
}

.redline-section {
    position: relative;
}

.redline-section ul li:first-child:before {
    content: " ";
    width: calc(100% - 34px);
    height: 0.1em;
    position: absolute;
    top: -5px;
    left: 34px;
    background-color: #f44336;
}

.redline-section ul li:first-child {
    margin-top: 10px;
}


.requires-final-version .redline-section .file-list .file-row {
    margin-left: 34px;
}

.requires-final-version .redline-section .file-list .mark-as-final {
    display: none;
}

.comments-down-arrow {
    padding-top: 3px;
    margin-left: 16px;
}

.approval-signature {
    display: inline-block;
    width: 50%;
    padding: 15px;
    vertical-align: middle;
}

.approval-signature i, .discontinued-field i{
    padding-left: 5px;
    color: #616161;
    font-size: 12px;
}

.approval-date {
    display: inline-block;
    width: 45%;
    padding: 15px 0px;
    vertical-align: middle;
}

.approval-date span {
    font-size: 40px !important;
}

.discontinued-field {
    display: inline-block;
    width: 30%;
    padding: 0 15px 15px;
    vertical-align: middle;
}

.chosen-container {
    margin-top: 5px;
    font-size: 14px;
}
.chosen-container-active{
    // border: 1px solid rgb(59, 172, 210);
    -webkit-box-shadow:none;
    box-shadow:none
}

.chosen-container-single .chosen-single {
    background: none !important;
    border-radius: 2px;
    height: 26.5px;
}
.border-shadow{
    -webkit-box-shadow:none;
    box-shadow:none 
}
.chosen-container .chosen-drop {
    width: 99%;
}

.col-9-width {
    width: calc(75% - 10px) !important;
}

.row > .padding-member-firm {
    padding-right: 10px;
}

.comments-length-section {
    float: right;
    padding-right: 5px;
}

.income-section-span {
    text-align: right;
    width: 100%;
    padding: 2px 5px 2px 2px;
    margin-top: 6px;
    float: right;
}

.div-comments-signature div {
    padding-right: 0px;
    padding-left: 0px;
}

.top-8 {
    top: 8px;
}

.div-comments-signature {
    display: flex;
    padding: 0px 22px 22px 20px;
}

.tooltip-dcf {
    position: relative;
    display: inline-block;
}

.tooltip-dcf + .tooltiptext-dcf {
    visibility: hidden;
    background-color: #0076a8;
    color: white;
    border-radius: 6px;
    text-align: justify;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 29px;
    margin-left: -14vw;
    width: auto;
    white-space: nowrap;
}

.tooltip-dcf:hover + .tooltiptext-dcf {
    visibility: visible;
}

.tooltip-dcf:hover:before + .tooltiptext-dcf {
    visibility: visible;
}

.templates-header { 
    margin-top: 5px;
}

#IndustryRepresentativeButtons {
    padding-bottom: 15px;
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

#functional-buttons-save {
    padding-bottom: 15px;
    padding-top: 20px;
}

#series24Box {
    padding-bottom: 20px;
    padding-top: 20px;
}

#dcfComplianceBox, #series24Box {
    padding-bottom: 20px;
}

.attachRecommendedSection .attachRecommendedButton {
    float: left;
    padding: 10px;
}

.modal-body-templates {
    overflow-y: auto;
}

a:visited {
    color: black;
}

.padding-ul-recommended-templates {
    padding-left: 0px;
    padding-right: 15px;
}

#document-type-templates {
    width: 100%;
    height: 40%;
}

.margin-left-30 {
    margin-left: 30px;
}

.break-filename {
    display: inline-block;
    word-break: break-all;
    overflow-wrap: break-word;
}

.notification {
    position: relative;
    display: inline-block;
}

.notification .badge {
    position: absolute;
    top: -6px;
    right: -10px;
    background-color: #F44336;
}

#comments-discontinue-dcf {
    padding-top: 20px;
}

.signature-date {
    font-size: 12px;
    width: 100%;
    text-align: center;
}

.discontinued-field .signature-date {
    text-align: left;
    font-size: 14px;
}

#review-assistant-modal .redline-attachment-section-modal .attachment-section-document-type .file-list {
    padding-left: 5px;
}

#review-assistant-modal .file-row {
    margin-right: 30px;
}

#review-assistant-modal .file-list .enabled ~ .file-row {
    margin-left: 0;
}

.attachments-rows  .file-row {
    margin-left: 34px;
}

div[id^="attachment-section-document-type-"] ul li {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
div[id^="attachment-section-financial-type"] ul li {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.enabled ~ .file-row {
    margin-left: 7px;
    flex: 1 1 auto;
}

.reviewers-view-buttons {
    padding-left: 15px;
    padding-right: 15px;
    text-align: right;
    margin: 0 0 10px 17px;
}

.reviewers-view-button {
    background-color: #FFFFFF;
    border-color: #337AB7;
    color: #337AB7;
    text-align: center;
    font-size: 12px;
    border: 2px solid #337AB7;
    width: 217px;
    line-height: 2.5em;
    margin-left: 10px;
}

.auto-save {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: green;
    color: white;
    width: 100%;
    z-index: 1000;
    font-style: italic;
    display: none;
 }

 .comments-right-arrow {
    font-size: 22px;
    float: left;
    color: #337ab7;
    cursor: pointer;   
}
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

 code[class*="language-"],
 pre[class*="language-"] {
     color: #f8f8f2;
     text-shadow: 0 1px rgba(0,0,0,0.3);
     font-family: Consolas, Monaco, 'Andale Mono', monospace;
     direction: ltr;
     text-align: left;
     white-space: pre;
     word-spacing: normal;
     
     -moz-tab-size: 4;
     -o-tab-size: 4;
     tab-size: 4;
     
     -webkit-hyphens: none;
     -moz-hyphens: none;
     -ms-hyphens: none;
     hyphens: none;
 }
 
 /* Code blocks */
 pre[class*="language-"] {
     padding: 1em;
     margin: .5em 0;
     overflow: auto;	
     border-radius: 0.3em;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
     background: #272822;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
     padding: .1em;
     border-radius: .3em;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
     color: slategray;
 }
 
 .token.punctuation {
     color: #f8f8f2;
 }

 .namespace {
     opacity: .7;
 }
 
 .token.property,
 .token.tag {
     color: #f92672;
 }
 
 .token.boolean,
 .token.number{
     color: #ae81ff;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string {
     color: #a6e22e;
 }
 
 
 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string {
     color: #f8f8f2;
 }
 
 .token.atrule,
 .token.attr-value
 {
     color: #e6db74;
 }
 
 
 .token.keyword{
 color: #66d9ef;
 }
 
 .token.regex,
 .token.important {
     color: #fd971f;
 }
 
 .token.important {
     font-weight: bold;
 }
 
 .token.entity {
     cursor: help;
 }
// .page-margin {
//     margin: 15px;
// }


.page-margin {
    margin: 15px;
}

.container-border-grey {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
}

.bg-title {
    background-color: #337AB7;
    color: white;
}

.submit-btn {
    background-color: #337AB7;
    border-color: #337AB7;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    border: 2px solid #337AB7;
}

.display-flex {
    display: flex;
}

.full-width {
	width: 100%;
}

.row {
    padding: 7px 19px;
}

.display-inline-block {
    display: inline-block;
}

.margin-top-5 {
    margin-top: 5px;
}

.bg-register {
	background-color: #fafafa;
}

.padding-people-picker {
	padding: 40px 20px;
}

.sp-peoplepicker-topLevel {
	width: 100% !important;
	border-radius: 3px;
}

.border-radius-title-register {
	border-radius: 9px 9px 0px 0px;
}

.border-radius-3 {
	border-radius: 3px;
}

.width-88 {
	width: 88%;
}

.float-right {
	float: right;
}

span.red-asterik:after {
    content: " *";
    color: red;
}

.bg-missingRequiredField {
    background-color: yellow;
}

.modal-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.align-center {
    text-align: center;
    // position: relative;
    // padding: 15px;
}
.modal-body-create{
    position: relative;
    padding: 15px;
}
// #submit-modal p, h1 {
//     color: #0072C6;
// }

// .modal-footer {
//     border-top: 0px !important;
// }

.bottom-5 {
	bottom: 5px;
    position: relative;
}
.forminitiate
{
    bottom: 32px !important;
}
#peoplePickerCreateDivMemberFirmTeam_chosen{
width: 1100px!important;
}
.btn-download-template {
    border-color: #BDBDBD;
    background-color: #FFFFFF;
    color: #212121;
    height: 2.5em !important;
    min-width: 6em;
}
.close-modal-btn {
    background-color: #E0E0E0;
    border-color: #9E9E9E;
    color: #212121; 
    height: 2.5em !important;
}
#hidden-x-scrollbar{
    overflow-x: hidden;
    margin-left: 14px;
}
#peoplePickerDivMemberFirmTeam_chosen
{
    width: 884px;
}
.modal-dialog{
    font-family: 'Open Sans',sans-serif !important;
}
h1, .ms-h1 {
    font-family: "Segoe UI Light","Segoe UI","Segoe",Tahoma,Helvetica,Arial,sans-serif!important;
    font-size: 2.3em!important;
    color: #777!important;
    font-weight: 200!important;
}
#submit-modal p, h1 {
    color: #0072C6!important;
}

h1 {
    font-size: 2em!important;
    margin: 0.67em 0!important;
}
body {
    font-family: 'Open Sans',sans-serif !important;
    left: 0;
}
.modal-footer {
    border-top: 0px !important;
}
.btn {
    min-width: 6em;

}
.external-comment{
    height: 50px!important;
}
.internal-comment{
    height: 50px!important;
}
.modal-dialog {
    width: 600px;
    margin: 0px!important;
}
.popover{
    opacity: 1!important;
}
.content {
    min-height: calc(100vh - 70px);
}

.footer {
    height: 50px;
}


footer {
    background: #42A5F5;
    color: white;
    line-height: 50px;
    padding: 0 20px;
}
#peoplePickerCreateDivMemberFirmTeam_chosen .chosen-search-input {
    width: 400px !important;
}
#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input {
    width: 325px !important;
}

.container-loader{
    position: absolute;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
}

.ngb-dp-arrow-btn{
    min-width: 1em;
    outline: none  !important;
  }
.container-loader .loader-animation {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    border: 4px solid #0cf;
    border-radius: 50%;
    
    animation: spin 0.75s infinite linear;

    display: inline-block;
    border-color: transparent;
    border-top-color: white;
    animation-duration: 1.2s;
}

.loader-animation::before, .loader-animation::after{
    left: -4px;
    top: -4px;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
    display: inline-block;
    border-color: transparent;
    border-top-color: white;
    animation-duration: 1.2s;
} 

.loader-animation::before{
    transform: rotate(120deg);
} 
.loader-animation::after {
    transform: rotate(240deg);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.modal-title-header-color{
    font-size: 1.46em!important;
}
#DCFInternalComments
{
    margin: 4px!important;
}
#peoplePickerCreateDivMemberFirmTeam_chosen.chosen-container .chosen-drop {
    width: 40%!important;
}
#peoplePickerDivMemberFirmTeam_chosen.chosen-container .chosen-drop {
    width: 40%!important;
}
//Pagination Styles
.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    pointer-events: none;
}
#Country_chosen .chosen-search-input {
    width: 285px !important;
}
.width-55 {
  width: 55% !important;
}
.width-80 {
    width: 80%!important;
}
#Country .chosen-container-active {
    border: none!important;
 box-shadow: none!important;
}
 .chosen-container-active .chosen-choices{
    -webkit-box-shadow:0 0 0px rgba(0,0,0,.3)!important;
    box-shadow:0 0 0px rgba(0,0,0,.3)!important;
}
.Header1{
    color: #337ab7!important;

}


a:focus {
    outline: 0px !important;
}

.row {
    page-break-inside: avoid;
}

#printFormButton {
    float: right;
    margin-right: -15px;
    padding-top: 6px;
    margin-bottom: -6px;
}
#printFormButtonNewDeal {
    float: right;
    margin-bottom: -6px;
}
#printFormButtonDealWBS {
    float: right;
    margin-bottom: -6px;
}
#printFormButtonClosingChecklist {
    float: right;
    margin-bottom: -6px;
  }
#AuditFormButton {
    float: right;
    margin-right: 1px;
    padding-top: 6px;
    margin-bottom: -6px;
}
  @page {
    margin: 0.3cm 0.5cm;
}

    @media print {      
        body { 
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            border: 1px solid #337AB7 !important;
            }

    .reviewed-icon-color {
      color: #4CAF50 !important;
    }
  
    p {
      display: inline-block;
    }
  
    textarea,
    button {
      height: auto !important;
      overflow: visible !important;
    }
  
    .bg-title {
      color: white !important;
      background-color: #337AB7 !important;
    }
    .title-section-print{
        color: white !important;
    }
  
    span.red-asterik:after {
      content: " *";
      color: red !important;
    }
  
    .templates>div {
      border-radius: 6px !important;
      text-align: center !important;
      background-color: #337AB7 !important;
      color: white !important;
    }
  
    .pending-icon-color {
      color: #FF9800 !important;
    }
  
    .btn-primary {
      color: #fff !important;
      background-color: #337ab7 !important;
      border-color: #2e6da4 !important;
    }
  
    .reviewed-icon-color {
      color: #4CAF50 !important;
    }
  
    .pending-icon-color {
      color: #FF9800 !important;
    }
  
    .glyphicon-file {
      color: #337ab7 !important;
      font-size: x-large !important;
    }
  
    .glyphicon {
      position: relative !important;
      top: 1px !important;
      display: inline-block !important;
      font-family: 'Glyphicons Halflings' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 1 !important;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
    }
  
    .approval-date {
      color: #4CAF50 !important;
    }
  
    .save-rework-cancel-btn {
      background-color: #FFFFFF !important;
      border-color: #337AB7 !important;
      color: #337AB7 !important;
      text-align: center !important;
      font-size: 12px !important;
      border: 2px solid #337AB7 !important;
    }
  
    .fa {
      font-size: 24px !important;
      font-weight: 900 !important;
      text-align: center !important;
      color: #4CAF50 !important;
    }
  
    .approval-date span {
      font-size: 40px !important;
    }
  
    .reviewed-icon-color {
      color: #4CAF50 !important;
    }
  
    .fa-check-circle:before {
      content: "\f058" !important;
      color: #4CAF50 !important;
    }
  
    .fa-clock-o:before {
      content: "\f017";
      color: #FF9800 !important;
    }
  
    .fa-times-circle:before {
      content: "\f057";
      color: #F44336 !important;
    }
  
    .glyphicon-file:before {
      content: "\e022";
      color: #337ab7 !important;
      font-size: x-large !important;
    }
  
    .fa-check-circle:before {
      content: "\f058";
      color: #4CAF50 !important;
    }
  
    .document-template {
      color: #FFFFFF;
    }
  
    .notification .badge {
      background-color: #F44336 !important;
    }
  
    .glyphicon-bell:before {
      content: "\e123";
      color: #fff !important;
    }
  
    .document-type-button-color-green {
      background-color: #5CB85C !important;
      border-color: #5CB85C !important;
    }
  
    #S24SuggestionId {
      color: rgb(176, 24, 24) !important;
    }
  
    #printFormButton {
      display: none;
    }
  
    .delete-uploaded-file-style {
      margin-top: -15px
    }
  
    .chosen-container-multi .chosen-choices li.search-choice {
      background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%) !important;
    }
  
    .bg-dcf-engagement-team {
      background-color: #e6e6e6 !important;
    }
  
  
    #Series24Signature,
    #Series24DateApproval {
      border: 0px !important;
      padding: 5px !important;
    }
  
  
    input[type="text"]:disabled,
    textarea:disabled,
    .chosen-disabled {
      background: #f1f1f1 !important;
      // border-color: #cccccc !important;
      // border: 1px solid !important;
    }
  
    select:disabled {
      color: darkgray !important;
    }
  
    .span-to-highlight {
      border: 0px !important;
    }
  
    .risk-section-box {
      border: 1px solid #bebebe !important;
      border-radius: 3px !important;
    }
  
    .half-a-border:before {
      padding: 0 !important;
      margin: 0 !important;
      display: block !important;
      content: "" !important;
      width: 1px !important;
      height: 50% !important;
      background-color: white !important;
      position: absolute !important;
      bottom: 0 !important;
      left: -1px !important;
    }
  
    :after,
    :before {
      -webkit-box-sizing: border-box !important;
      -moz-box-sizing: border-box !important;
      box-sizing: border-box !important;
    }
  
    .half-a-border:after {
      padding: 0 !important;
      margin: 0 !important;
      display: block !important;
      content: "" !important;
      width: 1px !important;
      height: 50% !important;
      background-color: white !important;
      position: absolute !important;
      bottom: 0 !important;
      right: -1px !important;
    }
  
    :after,
    :before {
      -webkit-box-sizing: border-box !important;
      -moz-box-sizing: border-box !important;
      box-sizing: border-box !important;
    }
  
    .input-data-person,
    #peoplePickerDivMemberFirmTeam_chosen {
      width: 100% !important;
    }
  
    #DCFEngagementTeam_chosen .chosen-search-input,
    #peoplePickerDivMemberFirmTeam_chosen .chosen-search-input,
    #DealTeamBusinessReviewer_chosen .chosen-search-input,
    #Series24_chosen .chosen-search-input {
      // color : white !important;
      width: 0px !important;
      background-color: white !important;
    }
  
    .half-a-border,
    .risk-section-box,
    .chosen-container-multi .chosen-choices {
      background-image: linear-gradient(#fff 100%, #fff 100%) !important;
    }
  
    .half-a-border {
      border-top: 1px solid #bebebe !important;
      border-left: 1px solid #bebebe !important;
      border-right: 1px solid #bebebe !important;
      border-radius: 3px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  
    .btn-approve {
      background-color: #5CB85C !important;
      border-color: #4CAE4C !important;
      color: #FFFFFF !important;
      border: 2px solid #4CAE4C !important;
    }
  
    .redline-section ul li:first-child:before {
      content: " ";
      width: calc(100% - 34px) !important;
      height: 0.1em !important;
      position: absolute !important;
      top: -5px !important;
      left: 34px !important;
      background-color: #f44336 !important;
    }
  
    .redline-section a {
      color: #f44336 !important;
    }
  
    .redline-section>.break-filename {
      color: #f44336 !important;
    }
  
    .redline-section span {
      color: #f44336 !important;
    }
  
    .bg-missingRequiredField {
      background-color: yellow !important;
    }
  
    #DCFEngagementTeam_chosen,
    #peoplePickerDivMemberFirmTeam_chosen,
    #DealTeamBusinessReviewer_chosen,
    #Series24_chosen {
      width: 100% !important;
    }

    #Country_chosen{
        width: 100% !important;
      }

      .chosen-single {
        border-radius: 5px !important;
      }
      #CapitalRaiseOption input[type=checkbox] {
        margin-right: 2px;
      }
      
      .container-border-color {
        border: 1px solid #337AB7 !important;
    }

  }
.form-control {
    height: 6vh;
}
#uploadInfoID:hover + .hide{
    display: block;
    color: red;
}

// ToolTip Styles start
.popover {
    background-color: #337AB7 !important;
    color: white;
    border-radius: 6px;
    text-align: left;
    padding: 7px;
    position: absolute;
    z-index: 1;
    margin-left: 25%;
    margin-top: 4px;
    width: auto;
    white-space: normal;
   // display: inline-table;//Commented for Mercury Pursuit role duplicacy
  }
  .popover{
    max-width: fit-content;
  }
  .popover-header {
    display: inline;
    font-size: 18px;
  }
  .popover-body a {
    color: white;
    text-decoration: underline;
  }  
  .section-question-sign {
    color: #337AB7;
  }
// ToolTip Styles end
// TypeHead Styles start
  .dropdown-menu>.active, .dropdown-menu>.active:hover, .dropdown-menu>.active:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7;
}
.dropdown-menu .dropdown-item {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333;
    white-space: nowrap;
    width: 100%;
    background: 0 0;
    text-align: inherit;
    border: 0;
}
// TypeHead Styles end

.highlight-warning
{
    background: #ff0;
}

.content-section {
    padding: 20px 0px;
}
//Alert Styles Start
.alert {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-top: 1%;
    margin-bottom: 0%;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 98%;
    margin-left: 1%;
    height: 10%;
  }
  .alertmsg{
    margin-left: 2%;
  }
  .close {
    float: right;
    font-size: 182%;
    font-weight: 800;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
}
//Alert Styles End
//AutoSave Styles Start
.highlight-warning{
    background: $color-warning-bg;
}

.edited-externally {
    background: $green-autosave-edited-externally;
}
  
.conflict-on-merge  {
    background: $yellow-autosave-conflicts;
}
//AutoSave Styles End

.comment-actions-section {
  float: right;
  padding-right: 5px;
  margin-top: 1%;
}

.comment-actions-section .additional-comments-button-action {
  font-size: 30px;
  cursor: pointer;
}

.comment-section {
  width: 97%;
  display: inline-block;
}

#arrow-comments {
  padding-top: 3px;
  margin-left: 0px;
  font-size: 22px;
  float: left;
  color: #337ab7;
  cursor: pointer;
}

.textarea-comments {
  border: 0px;
  width: 100%;
  resize: none;
  scroll-behavior: unset;
  top: 2px;
  position: relative;
  text-align: justify;
  color: #777;
  background-color: #fafafa;
}

.ul-additional-comment {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.comments-bar-color {
  border-left: 10px solid #e0e0e0;
  height: 70px;
}

.comments-down-arrow {
  padding-top: 3px;
  margin-left: 16px;
}

.li-additional-comment {
  height: 70px;
  margin-top: 8px;
}

.li-additional-comment #div-comment-bar {
  display: inline-block;
  margin-left: 10px;
}

#remove-additional-comments-button {
  margin-left: 15px;
}

#hr-additional-comments {
  margin: 5px 0px;
  border: 1px solid rgb(200, 200, 200);
}

.div-comments {
  width: 95%;
  bottom: 19px;
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  position: relative;
}

.comment-length-section {
  float: right;
  min-width: 100px;
  text-align: right;
}

.reviewed-icon-color {
  color: #4CAF50;
}

.return-for-rework-icon-color {
  color: #F44336;
}

/* added for termination cghecklist */

.ngb-dp-arrow-btn {
    padding: 0 0.25rem;
    margin: 0 0.5rem;
    border: none;
    background-color: transparent;
    z-index: 1;
}

.ngb-dp-arrow-btn {
    min-width: 1em;
}
#contentToConvert{
    .form-control{
        box-shadow: none;
    }
}

#dflowLinks a:link,
#dflowLinks a:visited {
    color: #337ab7 !important;
    text-decoration: underline !important;
}

#dflowLinks a {
    word-wrap: break-word !important;
    white-space: normal !important;
    display: inline-block !important;
    max-width: 100% !important;
    overflow-wrap: break-word !important;
}